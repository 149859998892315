.container {
  max-width: 1268px;
  padding: 0 18px;
  margin: auto;
}
.logo {
  display: flex;
  justify-content: center;
  margin-top: 66px;
  margin-bottom: 28px;
}
.description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(11, 12, 27, 0.6);
  max-width: 801px;
  margin: auto;
  margin-bottom: 36px;
}
.feedback_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0B0C1B;
  padding-bottom: 56px;
  border-bottom: 1px solid #90a1a7;
  a {
    color: #5858e0;
    &:hover {
      text-decoration: underline;
    }
  }
}
.mailmodo_logo {
  object-fit: contain;
}
.copyright_section {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  position: relative;
  z-index: 10;
  margin-top: 30px;
  margin-bottom: 60px;
  .current_year {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color: #000000;
    &[data-dark-theme="true"] {
      color: white;
    }
  }
  .links {
    display: flex;
    list-style-type: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    li {
      margin-left: 22px;
      margin-right: 22px;
      &:last-child {
        margin-right: 0;
        margin-left: 0;
        padding-left: 22px;
        border-left: 1px solid black;
      }
      &:nth-of-type(2) {
        margin-left: 0;
        padding-left: 22px;
        border-left: 1px solid black;
      }
    }
    a {
      color: #000000;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &[data-dark-theme="true"] {
      a {
        color: white;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 60px;
    margin-bottom: 12px;
    padding-top: 32px;
    border-top: 1px solid #cde1e9;
    img {
      width: 144px;
      height: 62px;
    }
  }
  .description {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    color: rgba(11, 12, 27, 0.5);
  }
  .feedback_text {
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 10px;
    border-bottom: none;
  }
  .trademark {
    font-size: 12px;
    line-height: 34px;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .copyright_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 0;
    position: relative;
    z-index: 10;
    .current_year {
      font-size: 12px;
      line-height: 34px;
      opacity: 0.67;
      text-align: center;
      order: 2;
      &[data-dark-theme="true"] {
        opacity: 1;
      }
    }
    .links {
      order: 1;
      font-size: 12px;
      line-height: 34px;
      opacity: 0.8;
      border-top: 1px solid #cde1e9;
      border-bottom: 1px solid #cde1e9;
      &[data-dark-theme="true"] {
        border-top: 1px solid #33343a;
        border-bottom: 1px solid #33343a;
      }
      padding: 19px 0;
      margin-bottom: 20px;
      justify-content: center;
      li {
        margin-left: 10px;
        margin-right: 10px;
        &:last-child {
          border-left: none;
          padding-left: 0;
          margin-left: 10px;
          margin-right: 10px;
        }
        &:nth-of-type(2) {
          border-left: none;
          padding-left: 0;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
