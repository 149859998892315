.container {
  min-height: calc(100vh - 170px);
  margin-top: 52px;
  margin-bottom: 38px;
  display: flex;
  .row {
    display: flex;
    max-width: 1032px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .col_left {
    width: 387px;
    max-width: 50%;
    padding-right: 59px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 30px;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        margin: 0;
      }
      .reset_link {
        display: flex;
        align-items: baseline;
        margin: 0;
        overflow: hidden;
        color: #b1aec2;
        cursor: pointer;
        img {
          align-self: center;
          margin-right: 3px;
        }
      }
      .show_more {
        display: none;
      }
    }
    .modes_container {
      margin-bottom: 32px;
      .title {
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #0e063d;
        margin-bottom: 12px;
      }
      .card {
        background-color: #f4f6fd;
        border: 1px solid #f4f6fd;
        border-radius: 8px;
        padding: 12px 24px;
        padding-right: 16px;
        max-height: 42px;
        overflow: hidden;
        transition: max-height 350ms ease-out;
        margin-bottom: 8px;
        cursor: pointer;
        .card_title {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          justify-content: space-between;
          .charvon {
            margin-top: -4px;
            transition: transform 300ms;
          }
        }
        .card_body {
          margin-top: 8px;
          .description {
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            color: #615e75;
            margin: 0;
            margin-bottom: 11px;
          }
          .note {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 19px;
            color: #615e75;
            margin: 0;
          }
        }
        &[data-is-active="true"] {
          max-height: 250px;
          transition: max-height 350ms ease-in;
          border: 1px solid #5858e0;
          .card_title {
            .charvon {
              margin-bottom: -4px;
              transform: rotate(180deg);
            }
          }
        }
        &[data-index="0"] {
          max-height: 42px;
        }
      }
    }
    .modes_container_mobile {
      display: none;
    }
    .switch_container {
      margin-bottom: 44px;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #0e063d;
        margin: 0;
        margin-bottom: 12px;
      }
      .buttons {
        display: flex;
        .button {
          background-color: #f4f6fd;
          border: 1px solid #f4f6fd;
          padding: 14px 16px;
          width: 50%;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          img {
            margin-right: 7px;
          }
          .label {
            margin: 0;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #0e063d;
          }
          .sub_label {
            margin: 0;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            color: rgba(14, 6, 61, 0.7);
          }
          &[data-is-active="true"] {
            border: 1px solid #5858e0;
            .label {
              color: #5858e0;
            }
            .sub_label {
              color: rgba(88, 88, 224, 0.7);
            }
          }
        }
      }
    }
    .share_preview {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 24px;
      img {
        display: inline-block;
      }
      p {
        padding-top: 2px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5858e0;
        margin: 0;
        margin-left: 7px;
      }
      &:hover {
        p {
          text-decoration: underline;
        }
      }
    }
    .dark_cta_container {
      background-color: #5858e0;
      background: url("https://res.cloudinary.com/mailmodo/image/upload/v1679382141/strapi/Frame_1110165333_022569129e.png");
      background-size: cover;
      border-radius: 12px;
      align-items: center;
      padding: 20px 18px 15px;
      display: flex;
      img {
        margin-right: 17px;
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin: 0;
        margin-bottom: 11px;
      }
      .link {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        margin: 0;
        cursor: pointer;
        span {
          text-decoration: underline;
        }
      }
    }
  }
  .col_right {
    background-color: #f6f8fc;
    overflow: hidden;
    border-radius: 8px;
    flex-grow: 2;
    max-width: 645px;
    position: relative;
    .note {
      position: absolute;
      top: 0;
      padding: 12px 20px;
      background-color: #ebeff8;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        cursor: pointer;
      }
      p {
        color: #1e1d24;
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }
    &[data-is-dark="true"] {
      padding-top: 44px;
      background-color: #0c141d;
    }
    &[data-is-note-visible="false"]{
      padding-top: 0;
    }
  }
  .dark_cta_mobile_container {
    display: none;
  }
  .reset_link_mobile {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .container {
    padding: 0 18px;
    margin-top: 24px;
    display: block;
    margin-bottom: 0;
    .row {
      display: block;
    }
    .col_left {
      width: 100%;
      max-width: none;
      padding: 0;
      margin-bottom: 32px;
      .header {
        margin-bottom: 20px;
        .title {
          font-size: 24px;
          line-height: 29px;
        }
        .reset_link {
          display: none;
        }
        .show_more {
          display: flex;
          align-items: baseline;
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          img {
            align-self: center;
            margin-left: 8px;
            transition: transform 300ms;
            &[data-is-active="true"] {
              transform: rotate(180deg);
            }
          }
        }
      }
      .modes_container {
        display: none;
      }
      .modes_container_mobile {
        margin-bottom: 32px;
        display: block;
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #0e063d;
        }
        .chips {
          display: flex;
          width: 100%;
          column-gap: 10px;
          margin-bottom: 12px;
          .chip {
            background-color: #f4f6fd;
            border-radius: 8px;
            flex-grow: 2;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #0e063d;
            padding: 12px 0;
            display: flex;
            justify-content: center;
            border: 1px solid transparent;
            &[data-is-active="true"] {
              border: 1px solid #5858e0;
              color: #5858e0;
            }
          }
        }
        .subtile1 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #615e75;
          margin: 0;
          margin-bottom: 12px;
        }
        .subtile2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #615e75;
          margin: 0;
        }
      }
      .switch_container {
        margin-bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 350ms ease-out, margin-bottom 350ms;
        .title {
          font-size: 14px;
          line-height: 17px;
        }
        .buttons {
          display: flex;
          column-gap: 12px;
          .button {
            padding: 12px 16px;
            border-radius: 8px;
            &[data-is-active="true"] {
              border: 1px solid #5858e0;
              .label {
                color: #5858e0;
              }
              .sub_label {
                color: rgba(88, 88, 224, 0.7);
              }
            }
          }
        }
        &[data-is-visible="true"] {
          max-height: 100px;
          margin-bottom: 32px;
          transition: max-height 350ms ease-in, margin-bottom 350ms;
        }
      }
      .share_preview {
        justify-content: center;
        margin-bottom: 0;
        img {
          display: inline-block;
        }
        p {
          line-height: 21px;
        }
        &[data-is-visible="false"] {
          display: none;
        }
      }
      .dark_cta_container {
        display: none;
      }
      &[data-is-visible="false"] {
        margin-bottom: 0;
      }
    }
    .col_right {
      display: flex;
    }
    .dark_cta_mobile_container {
      background-color: #5858e0;
      background: url("https://res.cloudinary.com/mailmodo/image/upload/v1679382141/strapi/Frame_1110165333_022569129e.png");
      background-size: cover;
      border-radius: 12px;
      align-items: center;
      padding: 20px 18px 15px;
      display: flex;
      margin-top: 32px;
      margin-bottom: 32px;
      img {
        margin-right: 17px;
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin: 0;
        margin-bottom: 11px;
      }
      .link {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        margin: 0;
        cursor: pointer;
        span {
          text-decoration: underline;
        }
      }
    }
    .reset_link_mobile {
      display: flex;
      align-items: baseline;
      margin: 0;
      overflow: hidden;
      color: #b1aec2;
      cursor: pointer;
      justify-content: center;
      margin-bottom: 44px;
      img {
        align-self: center;
        margin-right: 3px;
      }
    }
  }
}
