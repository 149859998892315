.image_container {
  width: 100%;
  padding-top: 64px;
  background-color: #080a1c;
  position: relative;
  margin-bottom: 460px;
  margin-top: -2px;
  .diamond_large_left {
    position: absolute;
    left: 100px;
    top: 176px;
    z-index: 2;
  }
  .diamond_medium_left {
    position: absolute;
    left: 280px;
    top: 80px;
    z-index: 2;
  }
  .diamond_small_left {
    position: absolute;
    left: 250px;
    top: 400px;
    z-index: 2;
  }
  .diamond_large_right {
    position: absolute;
    right: 130px;
    top: 270px;
    z-index: 2;
  }
  .diamond_medium_right {
    position: absolute;
    right: 230px;
    top: 480px;
    z-index: 2;
  }
  .diamond_small_right {
    position: absolute;
    right: 280px;
    top: 60px;
    z-index: 2;
  }
  .gradient_left_desktop {
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 1;
  }
  .gradient_bottom_desktop {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .gradient_top_left_mobile {
    display: none;
  }
  .gradient_bottom_right_mobile {
    display: none;
  }
}
.mobile {
  display: none;
}
.btndiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
}
.line_break {
  width: 100%;
  border: none;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(225, 223, 237, 0),
    #e1dfed 50%,
    rgba(225, 223, 237, 0)
  );
  max-width: 1240px;
  opacity: 0.25;
  margin: 0;
  margin: auto;
}
.text_image_container1 {
  position: relative;
  z-index: 2;
}
.header1 {
  color: rgb(249, 252, 255);
  font-size: 60px;
  margin: 0px;
  font-family: Inter, sans-serif;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 122.52%;
  text-align: center;
  color: #f9fcff;
}
.para1 {
  color: #f9fcff;
  margin: 16px 0px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 176.52%;
  text-align: center;
  color: #f9fcff;
  opacity: 0.85;
  margin-bottom: 52px;
  position: relative;
  z-index: 2;
}
.btn {
  z-index: 2;
  background: #5858e0;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
  height: 66px;
  transition: transform 400ms;
  padding-left: 32px;
  padding-right: 32px;
  min-width: max-content;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    opacity: 1;
    transform: translateY(-3px);
  }
}
.div_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.second_container {
  border-image-slice: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 37px;
  padding-bottom: 500px;
  position: relative;
  z-index: 2;
}
.group1 {
  display: flex;
  flex-direction: row;
}
.borderLine {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.para3 {
  text-align: center;
  color: white;
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #ffffff;
  margin-left: 0;
  font-size: 18px;
  margin-top: 10px;
}
.para2 {
  text-align: center;
  color: white;
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 53px;
  color: #ffffff;
}
.list1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-top: 24px;
  max-width: 978px;
}
.list_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list_1_1 {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(12, 56, 63);
  border-radius: 50%;
  height: 42px;
  width: 42px;
  min-width: 42px;
}
.para1_1 {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(30, 182, 131);
}
.text1 {
  margin: 0px 0px 0px 14px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  color: #eeeeee;
}
.text_1 {
  margin: 0px;

  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: rgb(238, 238, 238);
}
.image_1 {
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -456px;
  left: -140px;
  right: 0;
  width: 896px;
  margin: auto;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  .image_container {
    padding: 0;
    margin-bottom: 245px;
    .diamond_large_left {
      display: none;
    }
    .diamond_medium_left {
      display: none;
    }
    .diamond_small_left {
      display: none;
    }
    .diamond_large_right {
      display: none;
    }
    .diamond_medium_right {
      display: none;
    }
    .diamond_small_right {
      display: none;
    }
    .gradient_left_desktop {
      display: none;
    }
    .gradient_bottom_desktop {
      display: none;
    }
    .gradient_top_left_mobile {
      display: block;
      position: absolute;
      left: 0;
      top: -104px;
    }
    .gradient_bottom_right_mobile {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .image_2 {
    position: absolute;
    bottom: -250px;
    margin: auto;
    left: 0;
    right: 0;
    width: 90%;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
  .container_mobile {
    padding: 0 18px;
    padding-bottom: 195px;
  }
  .line_break {
    display: none;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .image_sub_container {
    background-color: #080a1c;
    background-image: none;
    background-repeat: repeat-x;
    background-position: top;
    mix-blend-mode: normal;
    height: 720px;
    width: 100%;
    top: 0px;
    background-size: 100% auto;
    padding-bottom: 200px;
  }
  .text_image_container1 {
    // min-width: 1200px;
    max-width: 1200px;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 0%;
  }
  .header1 {
    margin-top: 4px;
    font-size: 24px;
    line-height: 111.5%;
  }
  .para1 {
    margin-top: 8px;
    font-size: 14px;
    line-height: 143%;
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 20px;
  }
  .para3 {
    margin-top: 0;
  }
  .btndiv {
    margin-top: 0;
    width: 100%;
    margin-bottom: 32px;
    padding: 0 18px;
    a {
      width: 100%;
      button {
        width: 100%;
        margin: 0;
      }
    }
  }
  .btn {
    width: 324px;
    height: 46px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }
  .second_container {
    align-items: center;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 15px;
    border-image-slice: 0;
    padding-bottom: 0;
    padding: 24px 22px;
    margin: 0;
    border: 1px solid #ffffff1c;
  }
  .para2 {
    // display: flex;
    // align-items: flex-start;
    color: white;
    margin-left: -44%;
    font-size: 18px;
    line-height: 111.5%;
    margin-top: 10px;
  }
  .list1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
    margin-left: 0%;
  }
  .list_1 {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
  }
  .list_1_1 {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(12, 56, 63);
    border-radius: 50%;
    height: 29px;
    width: 29px;
    min-width: auto;
  }
  .para1_1 {
    margin: 0px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(30, 182, 131);
  }
  .text_1 {
    margin: 0px;

    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: rgb(238, 238, 238);
  }
  .image_1 {
    transform: scale(1);
    // height: 80;
    display: flex;
    margin-top: 60px;
    right: 15px;
    padding-right: 7%;
    // display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    // // align-items: center;
    // flex-direction: column;
    // // justify-content: center;
    // // position: absolute;
    // top: 72%;
  }
}
