.amp_not_available_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .heading {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #0e063d;
    margin: 0;
    margin-bottom: 30px;
  }
  .card {
    background: #ffffff;
    margin: 0 32px;
    padding: 27px;
    box-shadow: 0px 14px 21px #ced8ea;
    border-radius: 16px;
    hr {
      border-top: 1px solid #00000026;
    }
    .card_row {
      display: flex;
      margin-bottom: 33px;
      .card_body_left {
        .gif {
          border-radius: 11px;
          margin-right: 25px;
        }
      }
      .card_body_right {
        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #0e063d;
        }
        .list {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
          img {
            margin-right: 13px;
          }
          p {
            margin: 0;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #58517c;
          }
        }
      }
    }
    .footer {
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #0e063d;
        margin: 0;
      }
      .button {
        background: #5858e0;
        box-shadow: 0px 14px 34px rgba(89, 89, 224, 0.17);
        border-radius: 10px;
        padding: 17px 31px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: white;
        border: none;
        cursor: pointer;
        width: 215px;
        white-space: nowrap;
        display: inline-block;
        position: relative;
        @media (min-width: 800px) {
          transition: 0.5s;
          &:after {
            content: "->";
            position: absolute;
            opacity: 0;
            top: 16.5px;
            right: -16px;
            transition: 0.5s;
          }
          &:hover {
            padding-left: 10px;
          }
          &:hover:after {
            opacity: 1;
            right: 18px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .amp_not_available_container {
    padding-top: 30px;
    padding-bottom: 45px;
    .heading {
      font-size: 20px;
      line-height: 24px;
    }
    .card {
      background: #ffffff;
      margin: 0 14px;
      padding: 14px;
      border-radius: 16px;
      hr {
        display: none;
      }
      .card_row {
        display: block;
        margin-bottom: 32px;
        .card_body_left {
          margin-bottom: 13px;
          .gif {
            width: 100%;
            height: auto;
            margin-right: 0;
          }
        }
        .card_body_right {
          max-width: 90%;
          margin: auto;
          .title {
            text-align: center;
            margin-bottom: 19px;
          }
          .list {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
          }
        }
      }
      .footer {
        margin-top: 12px;
        display: block;
        .desc {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 20px;
        }
        .button {
          background: #5858e0;
          box-shadow: 0px 14px 34px rgba(89, 89, 224, 0.17);
          border-radius: 10px;
          padding: 15px 31px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: white;
          border: none;
          cursor: pointer;
          width: 100%;
          white-space: nowrap;
          display: inline-block;
          position: relative;
          @media (min-width: 800px) {
            transition: 0.5s;
            &:after {
              content: "->";
              position: absolute;
              opacity: 0;
              top: 16.5px;
              right: -16px;
              transition: 0.5s;
            }
            &:hover {
              padding-left: 10px;
            }
            &:hover:after {
              opacity: 1;
              right: 18px;
            }
          }
        }
      }
    }
  }
}
