.cta_container {
  max-width: 1270px;
  padding: 0 18px;
  margin: auto;
}
.hbm2t4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 730px;
  margin-bottom: 64px;
  background-image: url("https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/strapi/Frame_1110165188_d99389e8d0.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 17px;
}
.awardsMobile {
  margin-top: 0;
  // width: ;
  max-width: 100%;
  overflow: hidden;
  background: radial-gradient(
        77.25% 77.25% at 26.33% 0%,
        #24146c 0%,
        rgba(32, 13, 107, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #11122b;
  border-radius: 12px;
  flex: none;
  order: 5;
  flex-grow: 0;
}
.logo_box {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.img_container {
  display: flex;
  // width: 20%;
  // padding-left: 20px;
  // margin-left: 5%;
}
.box_main {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  width: 20%;
  margin-left: 80px;
}
.para1 {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 23.6949px;
  line-height: 1.5;
  color: white;
}
.text1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 135.52%;
  text-align: center;
  color: white;
  margin: 50px 0px 0px;
  font-family: Inter, sans-serif;
}
.block1 {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  -webkit-box-pack: center;
  justify-content: center;

  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }
}
.checks {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
}
.p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.text2 {
  margin: 0px 0px 0px 18px;
  font-family: Inter, sans-serif;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 22px;
  font-weight: inherit;
}
.btndiv {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  justify-items: center;
  margin-top: 66px;
  // margin-left: 60;
}
.btndivMobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin-top: 32px;
  margin-bottom: 54px;
}
.btn {
  z-index: 1;
  background: #5858e0;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  border: none;
  // min-width: 125px;
  height: 66px;
  width: 267px;
  // opacity: 0.9;
  transition: transform 400ms;
  padding-left: 32px;
  padding-right: 32px;
  min-width: max-content;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    opacity: 1;
    transform: translateY(-3px);
  }
}
.btnMobile {
  z-index: 2;
  background: #5858e0;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 287px;
  height: 44px;
  transition: transform 400ms;
  padding-left: 32px;
  padding-right: 32px;
  min-width: max-content;
  margin-left: 12px;
  margin-right: 12px;

  &[data-type="light"] {
    background: #fff;
    border-radius: 8px;
    color: #786aff;
    border: 1px solid #786aff;
  }

  &:hover {
    opacity: 1;
    transform: translateY(-3px);
  }
}
.mobile {
  display: none;
}
.logoMobile {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}
.mobilePara {
  margin-top: 28px;
  height: 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* or 141% */

  text-align: center;

  color: #ffffff;
}
.itemline1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.text3 {
  padding-left: 7px;
  max-width: 131px;
  height: 26px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  /* or */

  color: #ffffff;

  opacity: 0.85;
}
.division {
  display: flex;
  margin-top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // width: 0%;
  padding-left: 0;
}
@media screen and (max-width: 800px) {
  .list_mobile {
    padding: 0 18px;
  }
  .hbm2t4 {
    display: none;
  }
  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile_container{
    padding: 0 18px;
  }
}
