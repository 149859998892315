.blue_underline_cards {
  padding: 0 18px;
  margin-bottom: 125px;
  .head1 {
    margin: 0;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    color: #0b0c1b;
    margin-bottom: 8px;
  }
  .para2 {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #101d25;
    margin-bottom: 50px;
  }
  .container_main {
    margin-top: 10px;
  }
  .main_div_cards1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .main_sub_div_cards {
    box-sizing: border-box;
    width: 292px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 14px 64px rgba(1, 1, 88, 0.1);
    border-radius: 12px;
    border-bottom: 10px solid rgb(43, 43, 129);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    margin: 0 10px;
    margin-bottom: 16px;
  }
  .number_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    .head2 {
      margin: 0px;
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 42px;
      line-height: 83px;
      color: rgb(43, 43, 129);
      font-weight: 700;
    }
    .logo_up {
      display: flex;
      padding-left: 10px;
    }
  }
  .text_2 {
    margin: 0;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 53px;
    max-width: 235px;
  }
}
.image_cards_container {
  margin-bottom: 126px;
  width: 100%;
  img {
    max-width: 100%;
    object-fit: contain;
  }
  .text3 {
    padding-left: 28%;
    padding-right: 28%;
    font-weight: 600;
    font-size: 42px;
    line-height: 135.52%;
    text-align: center;
    color: rgb(14, 6, 61);
    margin: 0px;
    font-family: Inter, sans-serif;
  }
  .cardImage {
    margin-top: 64px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .cardImageMobile {
    display: none;
  }
  .cardImageMobileStyles {
    margin-top: 10px;
  }
}
.dark_cta_container {
  padding: 0 18px;
  margin-bottom: 125px;
  .container {
    position: relative;
    padding-left: 75px;
    padding-right: 44px;
    background: linear-gradient(180deg, #5c46e1 0%, #3b26bc 100%);
    max-width: 1228px;
    border-radius: 28px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 43px;
    overflow: hidden;
    .col_left {
      padding-top: 24px;
      position: relative;
      z-index: 2;
      .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        color: #fffeff;
        margin: 0;
        margin-bottom: 23px;
      }
      .description {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        color: #f9fcff;
        opacity: 0.85;
        margin: 0;
        margin-bottom: 47px;
      }
      .btn {
        background: #ffffff;
        box-shadow: 0px 14px 34px rgba(66, 66, 182, 0.19);
        border-radius: 10px;
        padding: 22px 80px;
        border: none;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #5c46e1;
        transition: transform 400ms;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
    .col_right {
      position: relative;
      z-index: 2;
      img {
        display: flex;
      }
      .mobile_image {
        display: none;
      }
    }
    .dots_right {
      position: absolute;
      right: 0;
      top: 0;
    }
    .dots_left {
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
    }
  }
}
.tweets_section {
  max-width: 1364px;
  padding: 0 18px;
  margin: auto;
  margin-bottom: 110px;
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    color: #0b0c1b;
    margin: 0;
    margin-bottom: 33px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      display: block;
    }
    .col {
      .tweet {
        max-width: 397px;
        box-shadow: 0px 7.56538px 34.5846px rgba(44, 0, 88, 0.08);
        margin: 0 8px;
        margin-bottom: 18px;
        cursor: pointer;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
// .arrow_animation_section {
//   margin-bottom: 125px;
//   padding: 0 18px;
//   .description {
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 32px;
//     line-height: 43px;
//     text-align: center;
//     color: #0b0c1b;
//     margin: 0;
//     margin-bottom: 22px;
//   }
//   .animation {
//     display: flex;
//     justify-content: center;
//   }
// }
.unlock_form_section {
  margin: auto;
  max-width: 1196px;
  padding: 0 18px;
  margin-bottom: 126px;
  .main_heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 55px;
    text-align: center;
    color: #0b0c1b;
    max-width: 736px;
    margin: auto;
    margin-bottom: 50px;
  }
  .container {
    background-color: #f6f8fc;
    border-radius: 43px;
    padding-top: 16px;
    position: relative;
    overflow: hidden;
    .heading {
      margin: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 27.571px;
      line-height: 37px;
      text-align: center;
      color: #271e77;
      &[data-is-blur="true"] {
        filter: blur(1px);
      }
    }
    .background_image_desktop {
      display: flex;
      max-width: 100%;
      object-fit: contain;
      object-position: bottom;
      height: auto;
    }
    .background_image_mobile {
      display: none;
    }
    .form_container {
      position: absolute;
      background: white;
      border: 2px solid rgba(39, 30, 119, 0.3);
      border-radius: 32px;
      width: 870px;
      height: 224px;
      max-width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      filter: drop-shadow(0px 2px 12px rgba(88, 88, 224, 0.15))
        drop-shadow(0px 41px 27px rgba(36, 36, 133, 0.1));
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 44px 48px 48px 48px;
      z-index: 2;
      .heading {
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #271e77;
        margin-bottom: 28px;
      }
      form {
        display: flex;
        width: 100%;
        justify-content: center;
        input {
          flex-grow: 2;
          max-width: 596px;
          height: 59px;
          border: 1px solid #5858e0;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          padding-left: 20px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          outline: none;
          &::placeholder {
            color: #282078;
            opacity: 0.6;
          }
        }
        button {
          background-color: #5858e0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          border: none;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          cursor: pointer;
          width: 174px;
        }
      }
      .message {
        margin: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        margin-top: 4px;
      }
    }
    .gradient {
      opacity: 0.6;
      .gradient_left {
        width: 347px;
        height: 221px;
        background: linear-gradient(
          180deg,
          rgba(45, 145, 237, 0) 0%,
          #2f8de4 100%
        );
        filter: blur(57.5713px);
        border-radius: 19.3517px;
        top: 0;
        left: 140px;
        bottom: 0;
        position: absolute;
        margin: auto;
      }
      .gradient_middle {
        position: absolute;
        width: 347px;
        height: 221px;
        background: linear-gradient(
          180deg,
          rgba(36, 83, 250, 0) 0%,
          #2453fa 100%
        );
        filter: blur(57.5713px);
        border-radius: 19.3517px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .gradient_right {
        position: absolute;
        width: 347px;
        height: 221px;
        background: linear-gradient(
          180deg,
          rgba(139, 68, 255, 0) 0%,
          #8c45ff 100%
        );
        filter: blur(57.5713px);
        border-radius: 19.3517px;
        right: 140px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .blue_underline_cards {
    margin-bottom: 40px;
    .head1 {
      margin: 0px;
      margin-bottom: 8px;
      font-style: normal;
      font-size: 24px;
      line-height: 126.5%;
      text-align: center;
    }
    .para2 {
      margin: 0;
      margin-bottom: 20px;
      padding-left: 18px;
      padding-right: 18px;
      font-size: 16px;
      line-height: 26px;
    }
    .main_sub_div_cards {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding: 20px;
    }
    .number_card {
      margin-bottom: 12px;
      .head2 {
        display: flex;
        flex-direction: row;
        font-size: 30px;
        line-height: normal;
        color: rgb(43, 43, 129);
      }
      .logo_up {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .text_2 {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      max-width: none;
      margin-bottom: 26px;
      br {
        display: none;
      }
    }
  }
  .image_cards_container {
    margin-bottom: 60px;
    .text3 {
      padding-left: 12%;
      padding-right: 12%;
      font-weight: 600;
      font-size: 24px;
      line-height: 126.5%;
      text-align: center;
      color: rgb(14, 6, 61);
      margin: 0;
      margin-bottom: 26px;
      font-family: Inter, sans-serif;
    }
    .cardImage {
      display: none;
    }
    .cardImageMobile {
      margin-top: 0px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .dark_cta_container {
    margin-bottom: 60px;
    .container {
      flex-direction: column;
      padding: 38px 18px 0;
      border-radius: 20px;
      .col_left {
        padding-top: 0;
        .heading {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          margin-bottom: 14px;
        }
        .description {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 30px;
        }
        .btn {
          width: 100%;
          padding: 13px 0;
          font-size: 16px;
          line-height: 19px;
        }
        margin-bottom: 32px;
      }
      .col_right {
        .desktop_image {
          display: none;
        }
        .mobile_image {
          display: block;
          max-width: 100%;
          object-fit: contain;
          object-position: bottom;
          margin: auto;
        }
      }
      .dots_right {
        display: none;
      }
      .dots_left {
        width: 300px;
        height: 300px;
      }
    }
  }
  .tweets_section {
    margin-bottom: 50px;
    .heading {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .row {
      .col {
        order: 2;
        &[data-order-mobile="1"] {
          order: 1;
        }
        a {
          &[data-hide-mobile="true"] {
            display: none;
          }
        }
        .tweet {
          max-width: 100%;
          margin: 0;
          height: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  // .arrow_animation_section {
  //   margin-bottom: 60px;
  //   .description {
  //     font-size: 20px;
  //     line-height: 27px;
  //     margin-bottom: 14px;
  //   }
  //   .animation {
  //     img {
  //       width: 40px;
  //       height: 40px;
  //     }
  //   }
  // }
  .unlock_form_section {
    margin-bottom: 0;
    padding: 0;
    .main_heading {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 4px;
    }
    .container {
      background-color: transparent;
      padding-top: 0;
      .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 0;
        padding: 0 40px;
      }
      .background_image_desktop {
        display: none;
      }
      .background_image_mobile {
        display: flex;
        max-width: 100%;
        object-fit: contain;
        object-position: bottom;
        height: auto;
        margin: auto;
        margin-top: -26px;
      }
      .form_container {
        max-width: 85%;
        border-radius: 12px;
        height: 251px;
        padding: 25px 14px;
        .heading {
          font-size: 20px;
          line-height: 135.52%;
          padding: 0;
        }
        form {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          input {
            font-size: 12px;
            line-height: 16px;
            border-radius: 6px;
            height: 46px;
            margin-bottom: 14px;
          }
          button {
            border-radius: 6px;
            font-size: 14px;
            line-height: 19px;
            width: 100%;
            height: 46px;
          }
        }
      }
      .gradient {
        opacity: 0.6;
        // display: none;
        .gradient_left {
          width: 103px;
          height: 251px;
          left: 56px;
        }
        .gradient_middle {
          width: 103px;
          height: 251px;
        }
        .gradient_right {
          width: 103px;
          height: 251px;
          right: 56px;
        }
      }
    }
  }
}
