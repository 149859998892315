.header {
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  .container {
    display: flex;
    justify-content: space-between;
    padding: 4.5px 102px;
    max-width: 1438px;
    margin: auto;
    align-items: center;
    a {
      display: flex;
      text-decoration: none;
    }
    img {
      position: relative;
      z-index: 20;
    }
    .powered_container {
      display: flex;
      align-items: center;
      position: relative;
      .heart {
        width: 20px;
        height: 18px;
        position: relative;
        .red_heart {
          position: absolute;
          top: -2px;
          left: -4px;
        }
      }
      .static {
        opacity: 0;
      }
      .text {
        margin: 0;
        margin-right: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 58px;
        color: #ffffff;
        padding-bottom: 4px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -4.5px;
        left: 0;
        background-color: #786aff;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
      &:hover {
        .static {
          opacity: 1;
        }
        .animation {
          opacity: 0;
        }
        .text {
          color: #5858e0;
        }
      }
      &[data-is-dark="true"] {
        .text {
          color: black;
        }
        &:hover {
          .text {
            color: #5858e0;
          }
        }
      }
      &[data-is-home-is-not-sticky="true"] {
        .text {
          color: white;
        }
        &:after {
          background-color: #20c58b;
        }
      }
    }
  }
  &[data-is-home="true"] {
    background-color: #080a1c;
  }
  &[data-is-stick="true"] {
    background-color: #ffffff;
  }
}
@media screen and (max-width: 800px) {
  .header {
    position: initial;
    z-index: 20;
    .container {
      display: flex;
      padding: 11px 18px;
      justify-content: space-between;
      box-shadow: none;
      .logo {
        width: 101px;
        height: 48px;
      }
    }
    &[data-is-stick="true"] {
      background-color: #ffffff;
    }
    &[data-is-home="true"] {
      background-color: #080a1c;
      .container {
        padding: 11px 0;
        flex-direction: column;
        align-items: center;
        .logo {
          width: 101px;
          height: 48px;
        }
        .powered {
          width: 173px;
          height: 36px;
        }
      }
    }
  }
}
