.container {
  margin-top: 76px;
  .steps {
    margin: 0;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #94a3b8;
    margin-bottom: 12px;
  }
  .progress_bar {
    display: flex;
    justify-content: center;
    margin-bottom: 128px;
    .bar {
      width: 247px;
      height: 3px;
      background-color: #e2e8f0;
      margin: 0 4px;
      transition: background-color 250ms;
      &[data-is-completed="true"] {
        background-color: #20c58b;
      }
    }
  }
  .form_container {
    padding: 0 18px;
    margin-bottom: 96px;
    .heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #0e063d;
    }
    .form {
      display: flex;
      max-width: 722px;
      margin: auto;
      .input_container {
        position: relative;
        flex-grow: 2;
        .copy_email_input {
          padding-left: 60px;
          padding-top: 2px;
          height: 60px;
          border: 1px solid #5858e0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #0e063d;
          outline: none;
        }
        .before_icon {
          position: absolute;
          left: 19px;
          top: 0;
          bottom: 0;
          height: 30px;
          width: 30px;
          margin: auto;
        }
      }
      .copy_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #fbfbff;
        border: none;
        background: #6363e1;
        border: 1px solid #5858e0;
        border-left: none;
        border-radius: 0px 10px 10px 0px;
        width: 145px;
        transition: background-color 200ms;
        cursor: pointer;
        img {
          margin-right: 12px;
        }
        &[data-is-copied="true"] {
          background-color: #5dc286;
        }
      }
    }
  }
  .next_btn_container {
    padding: 0 18px;
    display: flex;
    justify-content: center;
    .next_btn {
      background-color: #5858e0;
      border-radius: 10px;
      padding: 20px 44px;
      border: none;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      transition: background-color 200ms, opacity 200ms;
      &:hover {
        background-color: #3d3d9c;
      }
      &:disabled {
        opacity: 0.3;
        background-color: #5858e0;
        cursor: auto;
      }
    }
  }
  .message {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #0e063d;
    margin-bottom: 76px;
  }
  .final_btn_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .previous_button {
      background-color: #5858e0;
      border-radius: 10px;
      padding: 20px 44px;
      border: none;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      transition: background-color 200ms, opacity 200ms;
      &:hover {
        background-color: #3d3d9c;
      }
    }
    .back_link {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #5858e0;
      margin: 0;
      margin-top: 27px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .mobile_padding{
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .container {
    margin-top: 24px;
    min-height: calc(100vh - 194px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 48px;
    .progress_bar {
      padding: 0 10px;
      margin-bottom: 90px;
    }
    .form_container {
      margin-bottom: 0;
      .heading {
        font-size: 24px;
        line-height: 29px;
        margin-top: 0;
        margin-bottom: 48px;
      }
      .form {
        display: block;
        .input_container {
          .copy_email_input {
            border: 1px solid #5858e0;
            border-radius: 8px;
            height: 48px;
            padding-left: 42px;
            font-size: 16px;
            line-height: 19px;
          }
          .before_icon {
            position: absolute;
            left: 13px;
            top: 0;
            bottom: 0;
            height: 24px;
            width: 24px;
            margin: auto;
          }
        }
        .copy_btn {
          margin-top: 9px;
          width: 100%;
          height: 48px;
          border-radius: 8px;
          font-size: 16px;
          line-height: 19px;
          border-left: 1px solid #5858e0;
          img {
            margin-right: 12px;
          }
          &[data-is-copied="true"] {
            background-color: #5dc286;
          }
        }
      }
    }
    .next_btn_container {
      padding: 0 18px;
      position: fixed;
      bottom: 48px;
      width: 100%;
      .next_btn {
        width: 100%;
        padding: 13px 0;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .message {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #0e063d;
      margin: 0;
    }
    .final_btn_container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 18px;
      position: fixed;
      bottom: 48px;
      width: 100%;
      .previous_button {
        order: 2;
        width: 100%;
        padding: 13px 0;
        font-size: 16px;
        line-height: 19px;
      }
      .back_link {
        order: 1;
        margin: 0;
        margin-bottom: 19px;
      }
    }
    .mobile_padding{
      display: block;
    }
  }
}
