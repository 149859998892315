.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 29px 26px;
    h3 {
      margin: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #0e063d;
      padding-bottom: 8px;
    }
    span {
      cursor: pointer;
      color: #9c9c9c;
    }
  }
  .body {
    margin: 0 29px 42px;
    .title {
      margin: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #0e063d;
      margin-bottom: 13px;
    }
    .form {
      display: flex;
      column-gap: 6px;
      .input_container {
        position: relative;
        flex-grow: 2;
        input {
          padding-left: 22px;
          padding-bottom: 2px;
          padding-right: 40px;
          height: 52px;
          border: 1px solid #918ea3;
          border-radius: 8px;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #0e063d;
          outline: none;
        }
        .dark {
          filter: invert(100%) sepia(88%) saturate(251%) hue-rotate(172deg)
            brightness(115%) contrast(113%);
        }
        .before_icon {
          position: absolute;
          right: 9px;
          top: 0;
          bottom: 0;
          height: 20px;
          width: 20px;
          margin: auto;
        }
      }
      button {
        background-color: #5858e0;
        border-radius: 8px;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 125px;
        cursor: pointer;
        &[data-is-sent="true"] {
          background-color: #5dc286;
        }
      }
    }
    .warning {
      margin: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #0e063d;
      margin-top: 4px;
      color: #DC4C64;
    }
  }
}
.success_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 60px 0 55px;
  span {
    position: absolute;
    top: 21px;
    right: 21px;
    color: #9c9c9c;
    cursor: pointer;
  }
  h3 {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #0e063d;
  }
  p {
    margin: 0;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #282828;
  }
}
@media screen and (max-width: 800px) {
  .container {
    .header {
      padding: 21px 25px;
      padding-bottom: 0;
      align-items: flex-start;
      h3 {
        font-size: 19px;
        line-height: 23px;
        padding-bottom: 0;
      }
    }
    .body {
      margin: 32px 25px 32px;
      .title {
        text-align: center;
      }
      .form {
        flex-direction: column;
        row-gap: 8px;
        column-gap: 6px;
        button {
          height: 48px;
        }
      }
    }
  }
  .success_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 60px 0 55px;
    span {
      position: absolute;
      top: 21px;
      right: 21px;
      color: #9c9c9c;
      cursor: pointer;
    }
    h3 {
      margin: 0;
      margin-top: 24px;
      margin-bottom: 7px;
    }
  }
}
